<template>
  <section ref="rootEl" class="keyfacts-product pt-xxxl">
    <div class="keyfacts-product-inner wrapper">
      <h2
        ref="titleEl"
        class="keyfacts-product__title mb-xl"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h2>

      <ul ref="listEl" class="keyfacts-product__list">
        <li
          v-for="fact in content.facts"
          ref="itemsEl"
          :key="fact.title"
          class="keyfacts-product__item"
          :class="`bg-${fact.background_color || 'violet'}`"
        >
          <h3
            class="keyfacts-product__item__title"
            :class="{ h4: fact.titleSmall }"
            v-html="fact.title"
          ></h3>
          <div class="keyfacts-product__item__text" v-html="fact.text"></div>
        </li>
      </ul>
    </div>

    <div class="keyfacts-product__plus bg-cobalt">
      <SvgSprite
        v-if="content.info?.length"
        class="icon"
        symbol="ui-plus-bold"
        size="60"
      />
    </div>

    <KeyfactsShared
      v-if="content.info?.length"
      :content="{ items: content.info, ctas: [content.cta1, content.cta2] }"
      color="cobalt"
      class="keyfacts-product__shared"
    />
  </section>
</template>

<script lang="ts">
import type { KeyfactsSharedItem } from '@/components/keyfacts/Shared.vue'
import type { Link } from '@/types'

export interface KeyfactsProduct {
  title: string
  titleColor?: string
  facts: {
    titleSmall?: boolean
    title: string
    text: string
    background_color: string
  }[]
  background_color: string
  info: KeyfactsSharedItem[]
  cta1?: Link
  cta2?: Link
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import KeyfactsShared from '@/components/keyfacts/Shared.vue'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<KeyfactsProduct>,
    required: true,
  },
})

// Refs
const rootEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const listEl = ref<HTMLElement | null>(null)
const itemsEl = ref<HTMLElement[]>([])

// Create appear animation
const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]
  const isMobile = window.innerWidth < 768
  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' })

  tl.set(listEl.value, {
    opacity: 0,
  })
    .set(titleEl.value, {
      opacity: 0,
      y: 10,
    })
    .set(itemsEl.value, {
      opacity: 0,
      y: 20,
      scaleX: isMobile ? 0 : 1,
      scaleY: isMobile ? 1 : 0,
    })

  tl.add('start')
    .to(
      titleEl.value,
      {
        opacity: 1,
        y: 0,
        ease: '8020',
        duration: 0.35,
      },
      'start'
    )
    .to(
      listEl.value,
      {
        opacity: 1,
        ease: '8020',
        duration: 0.35,
      },
      'start'
    )
    .add('visible', 'start+=0.2')
    .to(itemsEl.value, {
      scaleY: 1,
      scaleX: 1,
      ease: '8020',
      duration: 0.4,
      stagger: 0.15,
    })
    .to(itemsEl.value, {
      opacity: 1,
      y: 0,
      duration: 0.35,
      stagger: 0.15,
    })
}

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(rootEl.value, () => {
    appear()
  })
})
</script>

<style lang="scss" scoped>
$bp: nav;

.keyfacts-product {
  overflow: hidden;
}

.keyfacts-product-inner {
  @include mq($until: nav) {
    padding: 0 0 $spacing * 3 0;
  }
}

.keyfacts-product__title {
  @include section-title;

  padding-right: col(1, var(--available-columns));
  padding-left: col(1, var(--available-columns));

  @include mq($bp) {
    padding-right: col(2, var(--available-columns));
    padding-left: col(2, var(--available-columns));
    text-align: center;
  }
}

.keyfacts-product__list {
  @extend %list-nostyle;

  @include mq($bp) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
  }

  @include mq(l) {
    padding-right: col(1, var(--available-columns));
    padding-left: col(1, var(--available-columns));
  }

  @include mq(xxl) {
    padding-right: col(2, var(--available-columns));
    padding-left: col(2, var(--available-columns));
  }
}

.keyfacts-product__item {
  max-width: 43rem;
  margin: 1rem 4rem 1rem 0;
  padding: 2rem;
  background: var(--c-accent-bg);
  border: 0.1rem var(--c-accent-border) solid;
  border-top-right-radius: 8rem;
  border-bottom-right-radius: 8rem;
  border-left: 0;
  transform-origin: left bottom;

  @include mq($bp) {
    width: 32%;
    margin: 0;
    padding: 5rem 2rem;
    text-align: center;
    border-top-left-radius: 20rem;
    border-top-right-radius: 20rem;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-left: 0.1rem var(--c-accent-border) solid;
    word-break: break-word;
    hyphens: auto;

    &:nth-child(1) {
      padding-bottom: 12rem;
    }

    &:nth-child(2) {
      padding-bottom: 24rem;
    }

    &:nth-child(3) {
      padding-bottom: 12rem;
    }
  }
}

.keyfacts-product__item__title {
  @extend %fw-light;

  margin: 0;
  font-family: $ff-alt;

  :deep(strong) {
    display: block;
    color: var(--c-accent-border);
  }

  &.h4 {
    line-height: 0.6;

    @include mq($bp) {
      margin-top: 3rem;
    }
  }

  @include mq(m) {
    text-align: center;
  }
}

.keyfacts-product__item__text {
  @extend %fw-normal;

  margin-top: $spacing * 0.25;

  :deep(p) {
    margin: 0;
  }

  @include mq($bp) {
    margin-top: $spacing;
  }
}

.keyfacts-product__plus {
  @include center-x;

  z-index: 1;
  display: grid;
  place-items: center;
  width: 6rem;
  height: 6rem;
  background: var(--c-accent-bg);
  border-radius: 50%;
  transform: translate(-50%, -40%);

  .icon {
    width: 3rem;
    height: 3rem;
    fill: var(--c-accent);
  }

  @include mq($bp) {
    width: 8rem;
    height: 8rem;

    .icon {
      width: 4rem;
      height: 4rem;
    }
  }
}
</style>
